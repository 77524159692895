import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, Subscriber} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppInjector} from '../../../core/app-injector.service';
import {DialogService} from '../../dialogs/dialog-service';
import {first, map} from 'rxjs/operators';
import {ConfirmDiscardUnsavedChanges} from './confirm-discard-unsaved-changes.interface';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class UnsavedChangesCanDeactivateGuard<T extends ConfirmDiscardUnsavedChanges>  {
	canDeactivate(component: T, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (component.hasUnsavedChanges) {
			return showDismissUnsavedChangesDialog().pipe(first(), map((value: boolean) => value));
		}
		return true;
	}
}

export const showDismissUnsavedChangesDialog = (message?: string): Observable<boolean> => {
	const dialogService = AppInjector.getInjector().get(DialogService);
	const translateService = AppInjector.getInjector().get(TranslateService);
	if (!message) {
		message = translateService.instant('genericText.unsavedChangesDialogMessage');
	}
	return new Observable<boolean>((observer: Subscriber<boolean>) => {
		const dialogRef = dialogService.openConfirmationDialog(message);
		dialogRef.componentInstance.onRightButtonClick = () => {
			dialogRef.close();
			observer.next(true);
			observer.complete();
		};
		dialogRef.componentInstance.onLeftButtonClick = () => {
			dialogRef.close();
			observer.next(false);
			observer.complete();
		};
	});
};
